import React, { useEffect } from 'react'
import styled from '@emotion/styled'
import tw, { TwStyle } from 'twin.macro'

declare global {
  interface Window {
    hbspt: any
  }
}

type Props = {
  config: {
    formId: string
    portalId: string
    region: string
  }
  style: {
    button: TwStyle
    email: TwStyle
    errorMsg: TwStyle
    form: TwStyle
    input: TwStyle
  }
}

const HubspotForm = ({ config, style }: Props) => {
  const htmlFormId = `hubspot-form-${config.formId}`

  const Styled = styled.div`
    .hs-form {
      ${style.form}
    }
    .hs-input {
      ${style.input}
    }
    .hs-button {
      ${style.button}
    }
    label:not(.hs-error-msg) {
      ${tw`hidden`}
    }
    .hs-email {
      ${style.email}
    }
    .hs-error-msg {
      ${style.errorMsg}
    }
  `

  useEffect(() => {
    const script = document.createElement('script')
    script.src = 'https://js.hsforms.net/forms/v2.js'
    document.body.appendChild(script)

    script.addEventListener('load', () => {
      // @TS-ignore
      if (window.hbspt) {
        // @TS-ignore
        window.hbspt.forms.create({ ...config, target: `#${htmlFormId}` })
      }
    })
  }, [])

  return <Styled id={htmlFormId}></Styled>
}

export default HubspotForm
