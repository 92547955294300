import { PageProps } from 'gatsby'
import HubspotForm from '../components/forms/hupspot_form'
import Layout from '../components/layout'
import React from 'react'
import tw from 'twin.macro'

const formConfig = {
  formId: '6c3cee15-6811-43c7-8677-c36313b86934',
  portalId: '7005624',
  region: 'na1',
}

const formStyle = {
  form: tw`bg-red-500`,
  input: tw`bg-blue-500`,
  button: tw`bg-green-500`,
}

const CampaignSamplePage: React.FC<PageProps> = () => (
  <Layout>
    <h1>HubSpot Form Customization Example</h1>
    <h2>
      Use Styled constant to customize style & hubspot form config constant to get parameters from
      our Hubspot Account
    </h2>

    <HubspotForm config={formConfig} style={formStyle} />
  </Layout>
)

export default CampaignSamplePage
